import React from "react";
import Hero from "../../Hero";
import Pricing from "../../Pricing";
import { homeObjOne, homeObjTwo, homeObjThree } from "./Data";

const SignUp = () => {
  return (
    <>
      <Hero {...homeObjTwo} />
      
    </>
  );
};

export default SignUp;