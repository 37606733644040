import React from "react";
import "./Hero.css";
import { Button } from "./Button";
import { Link } from "react-router-dom";
import { img1 } from "../assets";

const Hero = ({
  lightBg,
  topLine,
  lightText,
  lightTextDesc,
  headline,
  img,
  description,
  buttonLabel,
  alt,
  imgStart,
}) => {
  console.log(img);
  return (
    <div
      className={lightBg ? "home__hero--section" : "home__hero--section darkBg"}
    >
      <div className="container">
        <div
          className="row home__hero--row"
          style={{
            display: "flex",
            flexDirection: imgStart === "start" ? "row-reverse" : "row",
          }}
        >
          <div className="col">
            <div className="home__hero--text-wrapper">
              <div className="top__line">{topLine}</div>
              <h1 className={lightText ? "heading" : "heading dark"}>
                {headline}
              </h1>
              <p
                className={
                  lightTextDesc
                    ? "home__hero--subtitle"
                    : "home__hero--subtitle dark"
                }
              >
                {description}
              </p>
              <a href="https://api.leadconnectorhq.com/widget/form/cRcx3wKJFQoa749YwOq4">
                <Button buttonSize="btn--wide" buttonColor="blue">
                  {buttonLabel}
                </Button>
              </a>
            </div>
          </div>
          <div className="col">
            <div className="home__hero--img-wrapper">
              <img src={img} alt={alt} className="home__hero--img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
