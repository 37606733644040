import { img1, img2, img3, img4 } from "../../../assets";

export const homeObjOne = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Great rates Starting at $30/month",
  headline: "JAG Self Storage ",
  description:
    "Welcome to JAG Self Storage, a family-owned business dedicated to providing top-notch storage solutions and excellent customer service. We understand the importance of keeping your belongings safe and secure, and we are committed to helping you find the perfect storage solution to meet your needs!",
  buttonLabel: "Rent a Unit Today!",
  imgStart: "",
  img: img1,
  alt: "Credit Card",
};

export const homeObjTwo = {
  lightBg: false,
  lightText: false,
  lightTextDesc: false,
  topLine: "PROTECT WHAT YOU VALUE MOST",
  headline: "About Our Family",
  description:
    "Born and raised in the area, Joe and Amy planted their roots in Grand Bend and started their journey! They share a strong bond, love and support for one another and the community they have grown up in!",
  buttonLabel: "Rent a Unit Today!",
  imgStart: "start",
  img: img2,
  alt: "Vault",
};

export const homeObjThree = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "DATA ANALYTICS",
  headline: "Every transaction is stored on our secure cloud database",
  description:
    "Never ever have to worry again about saved reciepts. We store your data, so you can access it anytime.",
  buttonLabel: "Sign Up Now",
  imgStart: "",
  img: img3,
  alt: "Vault",
};
