import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { logo } from "../assets";
import { Button } from "./Button";
import { useEffect } from "react";
import "./Navbar.css";
import { IconContext } from "react-icons/lib";

const Navbar = () => {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const closeMobileMenu = () => setClick(false);
  const handleClick = () => setClick(!click);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);

  return (
    <>
      <IconContext.Provider value={{ color: "#444442" }}>
        <div className="navbar">
          <div className="navbar__container container">
            <Link to="/" classname="navbar__logo" onClick={closeMobileMenu}>
              <img className="navbar__logo" src={logo} alt="" />
            </Link>
            <div className="menu__icon" onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </div>
            <ul className={click ? "nav__menu active" : "nav__menu"}>
              <li className="nav__item" onClick={closeMobileMenu}>
                <Link className="nav__links" to="/">
                  Home
                </Link>
              </li>
              <li className="nav__item" onClick={closeMobileMenu}>
                <Link className="nav__links" to="/services">
                  Services
                </Link>
              </li>
              <li className="nav__btn">
                {button ? (
                  <a href="https://form.jotform.com/230296782237058">
                    <Button buttonStyle="btn__outline">
                      Rent a Unit Today!
                    </Button>
                  </a>
                ) : (
                  <a href="https://form.jotform.com/230296782237058">
                    <Button buttonStyle="btn--outline" buttonSize="btn--mobile">
                      Rent a Unit Today!
                    </Button>
                  </a>
                )}
              </li>
            </ul>
          </div>
        </div>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
