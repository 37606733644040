import React from "react";
import { FaFire } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Button } from "./Button";
import { BsGrid, BsCameraFill } from "react-icons/bs";
import { GiResize } from "react-icons/gi";
import { TbTruckLoading } from "react-icons/tb";
import { IconContext } from "react-icons/lib";
import "./Pricing.css";

const Pricing = () => {
  return (
    <IconContext.Provider value={{ color: "#b20000", size: 64 }}>
      <div>
        <div className="pricing__section">
          <div className="pricing__wrapper">
            <h1 className="pricing__heading">Services</h1>
            <div className="pricing__container">
              <Link to="/" className="pricing__container--card">
                <div className="pricing__container--cardInfo">
                  <div className="icon">
                    <BsCameraFill />
                  </div>
                  <h1>24/7 Surveillance and Access</h1>
                  {/* <h4>$8.99</h4>
                  <p>per month</p> */}
                  <ul className="pricing__container--features">
                    <li>
                    Having 24-hour surveillance and access for your self-storage place provides you with added peace of mind and security for your belongings.

                    </li>
                    <li>
                    The round-the-clock monitoring ensures that your property is protected against theft or damage, and you can access your unit at any time, day or night, with ease.
                    </li>
                  </ul>
                  
                </div>
              </Link>
              <Link to="/" className="pricing__container--card">
                <div className="pricing__container--cardInfo">
                  <div className="icon">
                    <GiResize />
                  </div>
                  <h1>Unit Sizes</h1>
                  <p>Starting at $30 per month</p>
                  <ul className="pricing__container--features">
                    <li className="big">5' X 5' X 8'</li>
                    <li className="big">8' X 8' X 8'</li>
                    <li className="big">8' X 12' X 8'</li>
                    <li className="big">9' X 9' X 6'</li>
                    <li className="big">9' X 12' X 6'</li>
                    <li className="big">9.5' X 22' X 8'</li>
                    <li className="big">12' X 17' X 8'</li>
                  </ul>
                  {/* <Button buttonSize="btn__wide" buttonColor="primary">
                    Choose Plan
                  </Button> */}
                </div>
              </Link>
              <Link to="/" className="pricing__container--card">
                <div className="pricing__container--cardInfo">
                  <div className="icon">
                    <TbTruckLoading />
                  </div>
                  <h1>Loading Dock</h1>
                  {/* <h4>$99.99</h4>
                  <p>per month</p> */}
                  <ul className="pricing__container--features">
                    <li>
                    Our self-storage facility features a convenient loading dock, making it easier than ever to move your belongings in and out. The dock provides a level surface for loading and unloading, allowing for a smooth and efficient process.
                    </li>
                    <li>
                    Take advantage of this convenient feature and make your move a stress-free experience!
                    </li>
                  </ul>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </IconContext.Provider>
  );
};

export default Pricing;
