import React from "react";
import "./Button.css";

const STYLES = ["btn__primary", "btn--outline"];

const SIZES = ["btn__medium", "btn__large", "btn__mobile", "btn__wide"];

const COLOR = ["primary", "red", "green"];

export const Button = ({
  children,
  type,
  onClick,
  buttonStyle,
  buttonSize,
  buttonColor,
}) => {
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0];

  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

  const checkButtonColor = COLOR.includes(buttonColor) ? buttonColor : COLOR[0];

  return (
    <a href="https://form.jotform.com/230296782237058">
      <button
        className={`btn ${checkButtonStyle}       ${checkButtonSize}
        ${checkButtonColor}`}
        onClick={onClick}
        type={type}
      >
        {children}
      </button>
    </a>
  );
};
