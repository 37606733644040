import { img1, img2, img3, img4 } from "../../../assets";

export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Sign Up",
  headline: "Unlimited Transactions with Zero fees",
  description:
    "Get access to our exclusive diamond card that allows you to send unlimted transaction without getting charged any fees",
  buttonLabel: "Get Started",
  imgStart: "",
  img: img1,
  alt: "Credit Card",
};

export const homeObjTwo = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "EASY SETUP",
  headline: "Super fast and simple onboarding process",
  description:
    "Get everything set up and ready in under 10 minutes. All you need to do is add your information and you're ready to go.",
  buttonLabel: "Start Now",
  imgStart: "start",
  img: img2,
  alt: "Vault",
};

export const homeObjThree = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "DATA ANALYTICS",
  headline: "Every transaction is stored on our secure cloud database",
  description:
    "Never ever have to worry again about saved reciepts. We store your data, so you can access it anytime.",
  buttonLabel: "Sign Up Now",
  imgStart: "",
  img: img3,
  alt: "Vault",
};