import React from "react";
import Hero from "../../Hero";
import Pricing from "../../Pricing";
import { homeObjOne, homeObjTwo, homeObjThree } from "./Data";

const Home = () => {
  return (
    <>
      <Hero {...homeObjOne} />
      <Hero {...homeObjTwo} />
      <Pricing />
      
    </>
  );
};

export default Home;
