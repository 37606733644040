import React from "react";
import "./Footer.css";
import { Button } from "../../Button";
import { Link } from "react-router-dom";
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";

import { logo } from "../../../assets";

function Footer() {
  return (
    <div className="footer-container">
      <section className="footer-subscription">
        <p className="footer-subscription-heading">
          Click the button and fill out the form in order to rent a unit! Once
          we have your information we will contact you as soon as we can!
        </p>
        <div className="input-areas">
          <Button buttonStyle="btn--outline">
            Click Here to Fill Out Our Form!
          </Button>
          <br />
          <br />
          <br />
          <a href="https://digitalperspective.ca/">
          <h3>Website Created By Digital Perspective</h3>
          </a>
        </div>
      </section>
      {/* <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>About Us</h2>
            <Link to='/sign-up'>How it works</Link>
            <Link to='/'>Testimonials</Link>
            <Link to='/'>Careers</Link>
            <Link to='/'>Investors</Link>
            <Link to='/'>Terms of Service</Link>
          </div>
          <div className='footer-link-items'>
            <h2>Contact Us</h2>
            <Link to='/'>Contact</Link>
            <Link to='/'>Support</Link>
            <Link to='/'>Destinations</Link>
            <Link to='/'>Sponsorships</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>Videos</h2>
            <Link to='/'>Submit Video</Link>
            <Link to='/'>Ambassadors</Link>
            <Link to='/'>Agency</Link>
            <Link to='/'>Influencer</Link>
          </div>
          <div className='footer-link-items'>
            <h2>Social Media</h2>
            <Link to='/'>Instagram</Link>
            <Link to='/'>Facebook</Link>
            <Link to='/'>Youtube</Link>
            <Link to='/'>Twitter</Link>
          </div>
        </div>
      </div> */}
      <section className="social-media">
      
        <div className="social-media-wrap">
          
          <div className="footer-logo">
            <Link to="/" className="social-logo">
              <img src={logo} alt="" />
            </Link>
          </div>
          <small className="website-rights">JAG Self Storage © 2023</small>
          <div className="social-icons">
            <a
              className="social-icon-link"
              href="https://www.facebook.com/profile.php?id=100078736702665"
            >
              <FaFacebook />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
